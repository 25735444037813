<template>
  <div>
    <div style="text-align: center" class="title justify-center">
      <p class="mb-0">{{ titulo }}</p>
    </div>
    <v-container fluid grid-list-md class="DashboardPendencias_Planejamento">
      <v-data-iterator
        :items="dados"
        :server-items-length="totalPage"
        :options.sync="pagination"
        :hide-default-footer="escondePaginacao"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 25, 50],
        }">
        <template v-slot:default="props">
          <v-row align="start" justify="center">
            <v-col
              cols="12"
              v-for="(item) in props.items"
              :key="item.id">
              <v-hover>
                <v-card
                  v-if="item.isContrato"
                  slot-scope="{ hover }"
                  :class="`elevation-${hover ? 5 : 1}`"
                  fill-height class="Card_Pendencia fill-height">
                  <v-card-title class="Card_Title">
                    <v-container fluid>
                      <v-row justify="space-between">
                        <span v-if="dashFornecedor || dashCliente" class="pa-0">
                          {{ `${$tc('label.contrato', 1)} #${item.id} :: ${item.solicitante}` }}
                        </span>
                        <span v-else class="pa-0">
                          {{ `${$tc('label.contrato', 1)} #${item.id} :: ${item.divisao}` }}
                        </span>
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn text icon
                              v-on="on"
                              @click.native="abrirDetalhamento(item)">
                              <v-icon>info</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $tc('label.detalhe', 2) }}</span>
                        </v-tooltip>
                      </v-row>
                    </v-container>
                  </v-card-title>
                  <v-card-text class="Card_Content">
                    <v-container fluid>
                      <v-row justify="center" align="center">
                        <v-col cols="12" v-if="!indModoVarejo && item.valor" class="pa-0">
                          <span>
                            {{ `${$tc('label.valor_contrato', 1)}: ${getMoney(item.valor)}`}}
                          </span>
                        </v-col>
                        <v-col cols="12" class="pa-0" v-if="indModoVarejo">
                          <span v-if="item.grupoFornecedor">{{ item.grupoFornecedor }}</span>
                          <span v-else>{{ item.fornecedor }}</span>
                          <span v-if="item.extensoes">{{ `- ${item.extensoes}`}}</span>
                        </v-col>
                        <v-col cols="12" class="pa-0" v-else>
                          <span v-if="item.holding">{{ item.holding }}</span>
                          <span v-else>{{ item.cliente }}</span>
                          <span v-if="dashCliente && item.divisao">{{ `- ${item.divisao}`}}</span>
                          <span v-else-if="item.extensoes">{{ `- ${item.extensoes}`}}</span>
                        </v-col>
                        <v-col cols="12" md="7" class="pa-0">
                          <span v-if="item.periodo">{{ item.periodo }}</span>
                          <span v-else>{{ `${item.dtaInicio} a ${item.dtaFim}` }}</span>
                        </v-col>
                        <v-col cols="12" md=5>
                          <v-spacer></v-spacer>
                          <planejamento-contrato-fluxo
                            :key="item.id"
                            :idContrato="item.id"
                            :status="item.status"
                            @recarregar="buscarDados()"
                            :exibir-acompanhamento="false"/>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
                <v-card
                  v-else
                  slot-scope="{ hover }"
                  :class="`elevation-${hover ? 5 : 1}`"
                  fill-height class="Card_Pendencia">
                  <v-card-title class="Card_Title">
                    <v-container fluid>
                      <v-row justify="space-between">
                        <span v-if="dashFornecedor || dashCliente" class="pa-0">
                          {{ `${$t('label.acao_avulsa')} #${item.id} :: ${item.solicitante}` }}
                        </span>
                        <span v-else-if="item.isAcaoContrato" class="pa-0">
                          {{ `${$tc('label.acao_contrato', 1)} #${item.id}` }}
                        </span>
                         <span v-else-if="item.idAcaoProjeto" class="pa-0">
                          {{ `${$tc('label.acao_projeto', 1)} #${item.id}` }}
                        </span>
                        <span v-else class="pa-0">
                          {{ `${$t('label.acao_avulsa')} #${item.id}` }}
                        </span>
                        <span>
                          <v-tooltip left v-if="indModoVarejo && item.numNotaDebito">
                            <template v-slot:activator="{ on }">
                              <v-btn text icon
                                     v-on="on"
                                     @click.native="exportarNota(item)">
                                <v-icon>receipt</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $tc('label.exportar', 1) }}</span>
                          </v-tooltip>
                          <v-tooltip left>
                            <template v-slot:activator="{ on }">
                              <v-btn text icon
                                v-on="on"
                                @click.native="abrirDetalhamento(item)">
                                <v-icon>info</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $tc('label.detalhe', 2) }}</span>
                          </v-tooltip>
                        </span>
                      </v-row>
                      <v-row justify="start" v-if="indModoVarejo && item.numNotaDebito">
                        <span>{{ `${$tc('label.num_nota_debito', 1)}: ${item.numNotaDebito}` }}</span>
                      </v-row>
                    </v-container>
                  </v-card-title>
                  <v-card-text class="Card_Content">
                    <v-container fluid>
                      <v-row justify="center" align="center">
                        <v-col cols="12" class="pa-0">
                          <span style="font-weight: bold" v-if="item.divisao && item.nomRegiao">{{`${item.divisao } :: ${item.nomRegiao}` }}</span>
                          <span style="font-weight: bold" v-else-if="item.divisao">{{ item.divisao }}</span>
                        </v-col>
                        <v-col cols="12" v-if="indModoVarejo && !item.metaIntervalo" class="pa-0">
                          <span>
                            {{ `${$tc('label.verba', 1)}: ${verbaPendencia(item)}`}}
                          </span>
                        </v-col>
                        <v-col cols="12" v-if="!indModoVarejo && (item.vlrInvestido || item.valor)" class="pa-0">
                          <v-col cols="6" v-if="item.valor" class="pa-0">
                            <span>
                              {{ `${$tc('label.valor_acao', 1)}: ${getMoney(item.valor)}`}}
                            </span>
                          </v-col>
                          <v-col cols="6" v-if="item.vlrInvestido" class="pa-0">
                            <span>
                              {{ `${$tc('label.vlr_investido', 1)}: ${getMoney(item.vlrInvestido)}`}}
                            </span>
                          </v-col>
                        </v-col>
                        <v-col cols="12" v-if="!indModoVarejo && item.vlrRoi" class="pa-0">
                          <span>
                            {{ `${$tc('label.roi', 1)}: ${getPercent(item.vlrRoi)}`}}
                          </span>
                        </v-col>
                        <v-col cols="12" v-if="!indModoVarejo && item.nomProjeto" class="pa-0">
                          <v-tooltip left max-width="450">
                            <template v-slot:activator="{ on }">
                              <v-card v-on="on" class="text-truncate" max-width="400" elevation="0">
                               <span>{{ `${$tc('label.projeto', 1)}: ${item.nomProjeto}`}} </span>
                              </v-card>
                            </template>
                            <span>{{ item.nomProjeto }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="12" class="pa-0" v-if="indModoVarejo">
                          <span v-if="item.grupoFornecedor">{{ item.grupoFornecedor }}</span>
                          <span v-else>{{ item.fornecedor }}</span>
                          <span v-if="item.extensoes">{{ `- ${item.extensoes}`}}</span>
                        </v-col>
                        <v-col cols="12" class="pa-0" v-else>
                          <span v-if="item.holding">{{ item.holding }}</span>
                          <span v-else-if="item.cliente">{{ item.cliente }}</span>
                          <span v-else>{{ item.regional }}</span>
                          <span v-if="dashCliente && item.divisao">{{ `- ${item.divisao}`}}</span>
                          <span v-else-if="item.extensoes">{{ `- ${item.extensoes}`}}</span>
                        </v-col>
                        <v-col cols="12" md="7" class="pa-0">
                          <span v-if="item.periodo">{{ item.periodo }}</span>
                          <span v-else>{{ `${item.dtaInicio} a ${item.dtaFim}` }}</span>
                        </v-col>
                        <v-col cols="12" md=5>
                          <v-spacer></v-spacer>
                          <planejamento-acao-fluxo
                            :key="item.id"
                            :acao="item"
                            :idAcao="item.id"
                            :status="item.status"
                            @recarregar="buscarDados()"
                            :exibir-acompanhamento="false"/>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import PlanejamentoAcaoFluxo from '@/spa/planejamento-acao/PlanejamentoAcaoFluxo';
import {
  getMoney,
  getPercent,
} from '../../common/functions/helpers';

import PlanejamentoContratoFluxo from '../planejamento-contrato/PlanejamentoContratoFluxo';
import { pendenciasFluxoAcao } from '../../common/resources/workflow-acao';
import exportacao from '../../common/resources/downloads';
import getBasePath from '../../common/functions/api-resource';

export default {
  name: 'dash-inicial-pendencias-planejamento',
  components: {
    PlanejamentoAcaoFluxo,
    PlanejamentoContratoFluxo,
  },
  props: {
    filtros: Object,
    dashFornecedor: {
      type: Boolean,
      default: false,
    },
    dashCliente: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 5,
    },
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dados: [],
      pagination: {
        itemsPerPage: 5,
      },
      escondePaginacao: true,
      totalPage: 0,
      filtrosAplicados: this.filtros,

      realizouPrimeiraBusca: false,
    };
  },
  computed: {
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    titulo() {
      if (this.title) {
        return this.title;
      }
      return this.$tc('title.pendencia_planejamento', 2);
    },
  },
  watch: {
    filtros: {
      handler() {
        this.filtrosAplicados = this.filtros;
        this.buscarDados();
        setTimeout(() => {
          this.realizouPrimeiraBusca = true;
        });
      },
    },
    pagination: {
      handler() {
        if (this.realizouPrimeiraBusca) {
          this.buscarDados();
        }
      },
      deep: true,
    },
  },
  methods: {
    getMoney,
    getPercent,
    verbaPendencia(pendencia) {
      const { verba } = pendencia;
      return verba === 'PERCENTUAL'
        ? this.$tc('label.percentual', 1)
        : this.$tc('label.monetario', 1);
    },
    buscarDados() {
      const params = {
        ...this.filtrosAplicados,
        tamanhoPagina: this.pagination.itemsPerPage,
        numeroPagina: this.pagination.page,
      };

      pendenciasFluxoAcao(params, this.$resource)
        .then((res) => {
          const { data } = res;
          if (!data.resposta.length && this.pagination.page > 1) {
            this.pagination.page -= 1;
            this.buscarDados();
            return;
          }
          this.dados = data.resposta;
          this.totalPage = data.quantidadeRegistrosPagina;
          this.escondePaginacao = (this.totalPage / this.pagination.itemsPerPage) <= 1;
          this.$forceUpdate();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    isAfter(data, outra) {
      return moment(data, 'YYYY-MM-DD').isAfter(moment(outra, 'YYYY-MM-DD'));
    },
    abrirDetalhamento(item) {
      if (item.isContrato || item.isAcaoContrato) {
        this.$router.push({ name: 'detalharContrato', params: { idContrato: item.idContrato, from: 'dashboard-inicial', carregaPassoAcoes: true } });
      } else {
        this.$router.push({ name: 'detalharAcao', params: { idAcao: item.id, from: 'dashboard-inicial' } });
      }
    },
    exportarNota(item) {
      const idAcao = item.id;
      const basePath = getBasePath('recebimento', 'relatorio');
      const resource = exportacao(this.$http);

      resource.downloadGet(basePath, {
        param: '',
        url: `nota-debito/preview/${idAcao}`,
      }).catch(() => {
        this.$toast('Erro ao exportar. Tente novamente.');
      });
    },
  },
  mounted() {
    this.pagination.itemsPerPage = this.itemsPerPage;
  },
};
</script>
<style>
.DashboardPendencias_Planejamento {
  padding-top: 0px;
}
.DashboardPendencias_Planejamento .Card_Pendencia .Card_Title {
  padding: 7px 7px 0px 7px ;
}
.DashboardPendencias_Planejamento .Card_Pendencia .Card_Title span {
  font-weight: 700;
  margin: 0px;
  font-size: 16px;
}
.DashboardPendencias_Planejamento .Card_Pendencia .Card_Content {
  padding: 7px 7px 0px 7px ;
}
.DashboardPendencias_Planejamento .Card_Pendencia .Card_Content span {
  font-size: 14px;
}
.DashboardPendencias_Planejamento .Card_Pendencia .Card_Title button span i,
.DashboardPendencias_Planejamento .Card_Pendencia .Card_Title span,
.DashboardPendencias_Planejamento .Card_Pendencia .Card_Content span,
.DashboardPendencias_Planejamento .Card_Pendencia .Card_Content button span i {
  color: rgba(0,0,0,.70) !important;
}
.DashboardPendencias_Planejamento .Card_Pendencia .Card_Actions {
  padding-top: 0px;
}
</style>
